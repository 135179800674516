@import "src/Theme/colors";
.home {
  background-color: black;
  overflow: hidden;
  .hero {
    display: flex;
    .heroleft {
      display: flex;
      flex-direction: column;
      width: 30%;
      .ZivovLogo_container {
        display: flex;
        flex-direction: row;
        .ZivovLogo {
          height: 2.5rem;
          margin: 2rem;
        }
        .zivov {
          padding-top: 1.2rem;
          font-size: 2.5rem;
          font-weight: bold;
          letter-spacing: 1px;
          color: #ffffff;
        }
      }

      .textPlain {
        padding: 0rem 3rem 0 4rem;
        text-align: left;
        font-size: 2.5rem;
        margin-top: 10%;
        color: #ffffff;
        margin-left: 25%;
        font-weight: 600;
      }
      .texthead {
        cursor: pointer;
        letter-spacing: 0px;
        color: white;
        font-weight: 400;
        text-decoration: none;
        text-align: center;
        opacity: 1;
        text-transform: capitalize;
        background: linear-gradient(to right, #0e428f 50%, #db6fa6);
        border-radius: 15px;
        height: 3rem;
        width: 76%;
        padding: 2px;
        margin-left: 36%;
        margin-top: 15%;
        .buttongradient {
          padding: 8px;
          background-color: #000000;
          width: 99.9%;
          border-radius: 12px;
          align-self: center;
          font-size: 1.2rem;
          user-select: none;
        }
      }

      .text {
        padding: 0rem 1rem 0 3.5rem;
        text-align: left;
        font-size: 3rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.8;
        margin-left: 25%;
        background: linear-gradient(
          to right,
          #0e428f 5%,
          #1030e6 10%,
          #e385c5 50%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .text_the {
        padding: 0rem 1rem 0 3.5rem;
        text-align: left;
        font-size: 3rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.8;
        margin-left: 25%;
        background: linear-gradient(
          to right,
          #0e428f 5%,
          #1030e6 10%,
          #e385c5 50%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .text_rev {
        padding: 0rem 1rem 0 3.5rem;
        text-align: left;
        font-size: 3rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.8;
        margin-left: 25%;
        background: linear-gradient(
          to right,
          #0e428f 5%,
          #1030e6 10%,
          #e385c5 90%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .path2 {
        padding: 4rem 0 2rem 0;
        width: 150%;
        height: 22rem;
        background-image: url("../../Assets/Images/homePath2.png");
        background-size: 133% 100%;
        background-repeat: no-repeat;
        margin-top: -4rem;
        margin-left: -8rem;
      }
    }
    .heroright {
      padding: 5rem 0 1rem 0;
      display: block;
      flex-direction: column;
      width: 70%;
      .mobile_container {
        position: absolute;
        top: 13%;
        left: 61%;
        width: 30%;
        height: 60%;
        margin-right: 15%;
        border-radius: 50%;
        filter: blur(30px);
        margin-top: 3%;
        //background: linear-gradient(147deg, #1d4aa5, #8e3b65);
      }
      .graphImage_Screen{
        height: 32rem;
        position: absolute;
        top: 4rem;
        right: 10%;
        align-self: center;
      }
     .Jhulan_Criket{
      position: absolute;
      top: 38rem;
      right: 13rem;
      .Jhulan_name{
        text-align: center;
          font-size:16px;
          color: #ffffff;
          font-weight: bold;
      }
    }
      .graphImage {
        height: 38rem;
        position: absolute;
        top: 4rem;
        right: 2%;
        align-self: center;
      }

      .zen {
        padding: 0 0 2rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 48%;
        margin-right: -10rem;
        background: transparent
          linear-gradient(
            146deg,
            rgba(14, 66, 143, 1) 0%,
            rgba(149, 123, 242, 1) 47%,
            rgba(227, 133, 197, 1) 100%
          )
          0% 0% no-repeat padding-box;
        border-radius: 90px;
        // opacity: 0.47;
        .text {
          height: 5rem;
          .text1 {
            text-align: left;
            font-size: 2.5rem;
            letter-spacing: 0px;
            color: #ffffff;
            text-transform: capitalize;
            opacity: 1;
            padding: 3rem;
          }
          .text2 {
            font-size: 0;
          }
        }
        .image {
          padding: 0;

          .zenModel {
            height: 20rem;
            object-fit: contain;
          }
        }
      }

      .zen:hover {
        padding: 0 0 2rem 2rem;
        display: flex;
        // flex-direction: column;
        // justify-content: space-between;

        width: 55rem;
        z-index: 1;
        margin-right: -10rem;
        margin-left: -30rem;

        .text {
          display: flex;
          flex-direction: column;

          justify-content: flex-start;
          height: 5rem;
          width: 50%;
          .text1 {
            text-align: left;
            font-size: 2.5rem;
            letter-spacing: 0px;
            color: #ffffff;
            text-transform: capitalize;
            opacity: 1;
            padding: 3rem;
          }
          .text2 {
            text-align: left;
            font-size: 1.5rem;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            padding: 0rem;
          }
        }
        .image {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;

          .zenModel {
            height: 21rem;
          }
        }
      }

      .care {
        display: flex;
        width: 30rem;
        width: 30%;
        flex-direction: column;
        justify-content: space-between;
        background: transparent
          linear-gradient(146deg, #0e428f 0%, #957bf2 47%, #e385c5 100%) 0% 0%
          no-repeat padding-box;
        border-top-left-radius: 90px;
        border-bottom-left-radius: 90px;
        z-index: 2;
        padding: 0 0 2rem 2rem;
        .text {
          .text1 {
            text-align: left;
            font-size: 2.5rem;
            letter-spacing: 0px;
            color: #ffffff;
            text-transform: capitalize;
            opacity: 1;
            padding: 3rem;
          }
          .text2 {
            font-size: 0;
          }
        }
        .image {
          padding-right: 2rem;
          .careModel {
            height: 20rem;
          }
        }
      }
      .care:hover {
        display: flex;

        width: 45rem;
        flex-direction: row;

        z-index: 2;
        padding: 0 0 2rem 2rem;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          width: 50%;
          .text1 {
            text-align: left;
            font-size: 2.5rem;
            letter-spacing: 0px;
            color: #ffffff;
            text-transform: capitalize;
            opacity: 1;
            padding: 3rem;
          }
          .text2 {
            text-align: left;
            font-size: 1.5rem;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            padding: 0rem;
          }
        }
        .image {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          padding-left: 2rem;

          .zenModel {
            height: 21rem;
          }
        }
      }
    }
  }
  .zen_container {
    min-height: 40%;
    height: 32rem;
    width: auto;
    margin: auto;
    text-align: center;
    margin-top: 10%;
    background: linear-gradient(to bottom, #0b1a30 0%, #752443 150%);

    .headingZen {
      padding-top: 1.3%;
      text-align: center;
      font-size: 2.5rem;
      color: #ffffff;
      text-transform: capitalize;
      font-weight: 700;
      width: 20%;
      display: table;
      margin: 0 auto;
    }
    .groupItems {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .mobileLayers {
        height: 35rem;
        display: table;
        margin-top: -15%;
        width: 20rem;
      }
      .Zen_info {
        width: 50%;
        .Zen_info_context {
          font-size: 20px;
          text-align: center;
          margin-left: 30%;
          color: #ffffff;
          font-weight: 500;
          display: table;
          margin: 0 auto;
          padding-top: 4rem;
          padding-bottom: 10rem;
        }
        .btn-grad {
          background: linear-gradient(to right, #0e5cd1 0%, #c65392 50%);
          height: 3rem;
          width: 20rem;
          border-radius: 30px;
          border: none;
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          margin-left: 40%;
          margin: 0 auto;
        }
        .btn-learn {
          color: #ffffff;
          width: 20rem;
          height: 3rem;
          border: none;
          padding-top: 10px;
          background: transparent;
          margin-left: 40%;
          font-weight: 700;
          margin: 0 auto;
        }
      }
      .girlInWhite {
        height: 25rem;
      }
    }
  }
  .care_container {
    min-height: 40%;
    height: 32rem;
    width: auto;
    margin: auto;
    text-align: center;
    margin-top: 10%;
    background: linear-gradient(to bottom, #0b1a30 0%, #752443 150%);

    .headingZen {
      padding-top: 1.3%;
      text-align: center;
      font-size: 2.5rem;
      color: #ffffff;
      text-transform: capitalize;
      font-weight: 700;
      width: 20%;
      display: table;
      margin: 0 auto;
    }
    .groupItems {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .mobileLayers {
        height: 25rem;
        display: table;
        margin-top: 0%;
        margin: 0 auto;
      }
      .Zen_info {
        //width: 40%;
        .Zen_info_context {
          font-size: 20px;
          text-align: center;
          margin-left: 30%;
          color: #ffffff;
          font-weight: 500;
          display: table;
          margin: 0 auto;
          padding-top: 4rem;
          padding-bottom: 10rem;
        }
        .btn-grad {
          background: linear-gradient(to right, #0e5cd1 0%, #c65392 50%);
          height: 3rem;
          width: 20rem;
          border-radius: 30px;
          border: none;
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          margin-left: 40%;
          margin: 0 auto;
        }
        .btn-learn {
          color: #ffffff;
          width: 20rem;
          height: 3rem;
          border: none;
          padding-top: 10px;
          background: transparent;
          margin-left: 40%;
          font-weight: 700;
          margin: 0 auto;
        }
      }
      .icons_grp {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 60%;
        padding-top: 5rem;
        margin: 0 auto;
      }
      .care_icons {
        height: 70px;
        width: 70px;
        align-self: center;
        background: linear-gradient(123deg, #042c72 0%, #c65392 80%);
        border-radius: 50px;
        .icon_set {
          height: 68px;
          width: 70px;
          padding: 20px;
        }
      }
      .iconText {
        color: #ffffff;
        margin-top: 10px;
        margin-left: -10px;
        text-align: center;
      }
      .girlInWhite {
        height: 30rem;
        text-align: flex-start;
      }
    }
  }
  .footer {
    margin-top: 10%;
  }
  .choose {
    padding-top: 2rem;
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .bullet {
      width: 40px;
      //   height: 1px;
      border: 3px solid #777777;
      opacity: 1;
      background-color: #777777;
    }
    .text {
      padding-left: 10px;
      text-align: left;
      font-size: 1rem;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: capitalize;
      opacity: 1;
    }
  }
  .socials {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 2rem 20rem;
  }

  .download {
    padding-left: 5rem;
    text-align: left;
    font: normal normal normal 16px/19px Cabinet Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

//
@media only screen and (max-width: 868px) {
  .home {
    background-color: black;
    overflow: hidden;
    .hero {
      .heroleft {
        width: 100%;
        align-self: center;
        .ZivovLogo_container {
          .ZivovLogo {
            height: 2.5rem;
            margin: 2rem;
          }
          .zivov {
            display: block;
            padding-top: 1rem;
            font-size: 3rem;
            font-weight: bold;
            letter-spacing: 1px;
            color: #ffffff;
          }
        }

        .textPlain {
          padding: 0rem 3rem 0 4rem;
          text-align: left;
          font-size: 2.5rem;
          margin-top: 10%;
          font-weight: normal;
          color: #ffffff;
          margin-left: 0%;
        }
        .texthead {
          cursor: pointer;
          letter-spacing: 0px;
          color: white;
          font-weight: 400;
          opacity: 1;
          text-transform: capitalize;
          background: linear-gradient(to right, #0e428f 50%, #db6fa6);
          border-radius: 15px;
          height: 3rem;
          width: 80%;
          align-self: center;
          margin-top: 2rem;
          margin-left: 4rem;
          padding: 2px;

          .buttongradient {
            background-color: #000000;
            height: 2.8rem;
            padding: 5px;
            border-radius: 12px;
            align-self: center;
            font-size: 1rem;
            user-select: none;
            padding-top: 9px;
          }
        }

        .text {
          padding: 0rem 1rem 0 3.5rem;
          text-align: left;
          font-size: 3rem;
          font-weight: 500;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 0.8;
          margin-left: 0%;
          background: linear-gradient(
            to right,
            #0e428f 5%,
            #1030e6 10%,
            #e385c5 50%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .text_the {
          padding: 0rem 1rem 0 3.5rem;
          text-align: left;
          font-size: 3rem;
          font-weight: 500;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 0.8;
          margin-left: 0%;
          background: linear-gradient(
            to right,
            #0e428f 5%,
            #1030e6 10%,
            #e385c5 50%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .text_rev {
          padding: 0rem 1rem 0 3.5rem;
          text-align: left;
          font-size: 3rem;
          font-weight: 500;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 0.8;
          margin-left: 0%;
          background: linear-gradient(
            to right,
            #0e428f 5%,
            #1030e6 10%,
            #e385c5 90%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .path2 {
          padding: 4rem 0 2rem 0;
          width: 150%;
          height: 22rem;
          background-image: url("../../Assets/Images/homePath2.png");
          background-size: 133% 100%;
          background-repeat: no-repeat;
          margin-top: -4rem;
          margin-left: -8rem;
        }
      }
      .heroright {
        padding: 5rem 0 1rem 0;
        display: block;
        flex-direction: column;
        width: 100%;
        .mobile_container {
          position: absolute;
          top: 33rem;
          left: 1rem;
          width: 90%;
          height: 75%;
          margin-right: 0%;
          border-radius: 50%;
          filter: blur(30px);
          margin-top: 3%;
          //background: linear-gradient(147deg, #1d4aa5, #8e3b65);
        }
        .graphImage {
          position: absolute;
          top: 34rem;
          left: 4rem;
          margin: 0 auto;
        }
        .graphImage_Screen{
          height: 25rem;
          position: absolute;
          top: 35rem;
          right: 15%;
          align-self: center;
        }
       .Jhulan_Criket{
        position: absolute;
        top: 62rem;
        right:6.5rem;
        .Jhulan_name{
          text-align: center;
            font-size:16px;
            color: #ffffff;
            font-weight: bold;
        }
      }

        .zen {
          position: relative;
          top: 130%;
          padding: 0 0 2rem 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 28%;
          margin-right: -10rem;
          background: transparent
            linear-gradient(
              146deg,
              rgba(14, 66, 143, 1) 0%,
              rgba(149, 123, 242, 1) 47%,
              rgba(227, 133, 197, 1) 100%
            )
            0% 0% no-repeat padding-box;
          border-radius: 90px;
          // opacity: 0.47;
          .text {
            height: 5rem;
            .text1 {
              text-align: left;
              font-size: 2.5rem;
              letter-spacing: 0px;
              color: #ffffff;
              text-transform: capitalize;
              opacity: 1;
              padding: 3rem;
            }
            .text2 {
              font-size: 0;
            }
          }
          .image {
            padding: 0;

            .zenModel {
              height: 20rem;
              object-fit: contain;
            }
          }
        }

        .zen:hover {
          padding: 0 0 2rem 2rem;
          display: flex;
          // flex-direction: column;
          // justify-content: space-between;

          width: 55rem;
          z-index: 1;
          margin-right: -10rem;
          margin-left: -30rem;

          .text {
            display: flex;
            flex-direction: column;

            justify-content: flex-start;
            height: 5rem;
            width: 50%;
            .text1 {
              text-align: left;
              font-size: 2.5rem;
              letter-spacing: 0px;
              color: #ffffff;
              text-transform: capitalize;
              opacity: 1;
              padding: 3rem;
            }
            .text2 {
              text-align: left;
              font-size: 1.5rem;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
              padding: 0rem;
            }
          }
          .image {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;

            .zenModel {
              height: 21rem;
            }
          }
        }

        .care {
          display: flex;
          width: 30rem;
          width: 30%;
          flex-direction: column;
          justify-content: space-between;
          background: transparent
            linear-gradient(146deg, #0e428f 0%, #957bf2 47%, #e385c5 100%) 0% 0%
            no-repeat padding-box;
          border-top-left-radius: 90px;
          border-bottom-left-radius: 90px;
          z-index: 2;
          padding: 0 0 2rem 2rem;
          .text {
            .text1 {
              text-align: left;
              font-size: 2.5rem;
              letter-spacing: 0px;
              color: #ffffff;
              text-transform: capitalize;
              opacity: 1;
              padding: 3rem;
            }
            .text2 {
              font-size: 0;
            }
          }
          .image {
            padding-right: 2rem;
            .careModel {
              height: 20rem;
            }
          }
        }
        .care:hover {
          display: flex;

          width: 45rem;
          flex-direction: row;

          z-index: 2;
          padding: 0 0 2rem 2rem;
          .text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            width: 50%;
            .text1 {
              text-align: left;
              font-size: 2.5rem;
              letter-spacing: 0px;
              color: #ffffff;
              text-transform: capitalize;
              opacity: 1;
              padding: 3rem;
            }
            .text2 {
              text-align: left;
              font-size: 1.5rem;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
              padding: 0rem;
            }
          }
          .image {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;
            padding-left: 2rem;

            .zenModel {
              height: 21rem;
            }
          }
        }
      }
    }
    .zen_container {
      min-height: 40%;
      height: 80%;
      width: auto;
      margin-top: 170%;
      background: linear-gradient(to bottom, #0b1a30 0%, #752443 150%);

      .headingZen {
        padding-top: 10%;
        text-align: center;
        font-size: 2rem;
        width: 40%;
        color: #ffffff;
        text-transform: capitalize;
        font-weight: 700;
      }
      .groupItems {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .mobileLayers {
          height: 30rem;
          width: 15rem;
          margin: 0 auto;
          padding-left: -5rem;
        }
        .Zen_info {
          width: 100%;
          .Zen_info_context {
            font-size: 18px;
            text-align: center;
            margin: 0 auto;
            color: #ffffff;
            font-weight: 500;
            padding-top: 20px;
            padding-bottom: 30px;
          }
          .btn-grad {
            background: linear-gradient(to right, #0e5cd1 0%, #c65392 50%);
            height: 3rem;
            width: 20rem;
            border-radius: 30px;
            border: none;
            color: #ffffff;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            margin: 0 auto;
          }
          .btn-learn {
            color: #ffffff;
            width: 20rem;
            height: 3rem;
            border: none;
            background: transparent;
            font-weight: 700;
            margin: 0 auto;
          }
        }
        .girlInWhite {
          height: 22rem;
          width: 80%;
          margin-top: 10%;
          //margin-right: 2%;
          align-self: center;
        }
      }
    }
    .care_container {
      min-height: 40%;
      height: 80%;
      width: auto;
      margin-top: 20%;
      background: linear-gradient(to bottom, #0b1a30 0%, #752443 150%);

      .headingZen {
        padding-top: 10%;
        text-align: center;
        font-size: 2rem;
        width: 50%;
        color: #ffffff;
        text-transform: capitalize;
        font-weight: 700;
      }
      .groupItems {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .mobileLayers {
          height: 25rem;
          width: 15rem;
          padding-top: 50px;
          margin: 0 auto;
          padding-left: -5rem;
        }
        .Zen_info {
          width: 100%;
          .Zen_info_context {
            font-size: 18px;
            text-align: center;
            margin: 0 auto;
            color: #ffffff;
            font-weight: 500;
            padding-top: 20px;
            padding-bottom: 30px;
          }
          .btn-grad {
            background: linear-gradient(to right, #0e5cd1 0%, #c65392 50%);
            height: 3rem;
            width: 20rem;
            border-radius: 30px;
            border: none;
            color: #ffffff;
            font-size: 20px;
            font-weight: 700;
            margin-top: 15%;
            margin-left: 8%;
            text-align: center;
            margin: 0 auto;
          }
          .btn-learn {
            color: #ffffff;
            width: 20rem;
            height: 3rem;
            border: none;
            background: transparent;
            margin-left: 8%;
            margin-top: 1%;
            font-weight: 700;
            margin: 0 auto;
          }
        }
        .girlInWhite {
          height: 30rem;
          align-self: center;
        }
        .icons_grp {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
          margin-top: 5%;
        }
        .care_icons {
          height: 70px;
          width: 70px;
          align-self: center;
          background: linear-gradient(123deg, #0e5cd1 0%, #c65392 80%);
          border-radius: 50px;
          .icon_set {
            height: 68px;
            width: 70px;
            padding: 20px;
          }
        }
        .iconText {
          color: #ffffff;
          margin-top: 10px;
          margin-left: -10px;
          text-align: center;
        }
      }
    }
    .footer {
      margin-top: 10%;
    }
    .choose {
      padding-top: 2rem;
      padding-bottom: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .bullet {
        width: 40px;
        //   height: 1px;
        border: 3px solid #777777;
        opacity: 1;
        background-color: #777777;
      }
      .text {
        padding-left: 10px;
        text-align: left;
        font-size: 1rem;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: capitalize;
        opacity: 1;
      }
    }
    .socials {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 2rem 0rem;
    }

    .download {
      padding-left: 0rem;
      text-align: center;
      font: normal normal normal 16px/19px Cabinet Grotesk;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
}
