$whiteColorHeader: hsl(0, 0%, 100%);
$transparentColor: #f7fbff;
$strangeText: #00f;
$primary: #011f40;
$lightGrey: #fbfbf9;
$headerMenuColor: #1f2633;
$darkBlue: #0f3a6c;
$darkGrey: #3f3f3f;
$dashbaordGradient: #1460c4;
$dashbaordGradient1: #0c4591;
$green: #7fba00;
$peach: #ffb900;
$red: #f25021;
$grey1: #f5f5f5;
$grey3: #ededed;
$grey5: #7e7d7d;
$transparentBackgroud: rgba(0, 0, 0, 0.5);
$backgroungColor1: #484848;
$backgroungColor2: rgb(34, 34, 34);
$blackBackground: rgb(0, 0, 0);
$whiteBackground: #fff;
$strangeBackground: #00f;
$grey1: #efeff3;
$grey2: #63686d;
$grey3: #b0b0b3;
$grey4: #bebebf;
$grey5: #7e7d7d;
$grey6: #ededed;
$grey7: #e6e6ed;
$grey8: #2d2d2d;

/*
statusBar: '#1475C4',
primary: '#3D9BE9',
parimaryLight: '#B3DCFF',
secondary: '',
tableHeader: '#30a1ff',
grey1: '#EFEFF3',
grey2: '#63686D',
grey3: '#B0B0B3',
grey4: '#BEBEBF',
grey5: '#7E7D7D',
grey6: '#ededed',
grey7: '#e6e6ed',
grey8: '#2d2d2d',
white: '#FFFFFF',
black: '#000000',
green: '#7fba00',
peach: '#ffb900',
red: '#f25021',
darkBlue: '#0f3a6c',
primaryColorOpacity: 'rgba(61, 155, 233, 0.3)',
whiteLightColor: 'rgba(255, 255, 255, 0.95)',
transparentBackgroud: 'rgba(0, 0, 0, 0.5)',
dashbaordGradient: '#1460c4',
dashbaordGradient1: '#0c4591',
chartLineColor: 'rgba(255, 255, 255, 0.55)',
chartGridColor: 'rgba(255, 255, 255, 0.1)',
switchButton: 'rgba(255, 255, 255, 0.3)',
lightblue: '#f5f9fb',
*/
