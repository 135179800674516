@import "src/Theme/colors";

.selectorContainer {
  background: black;
  align-content: center;
  height: 100%;
  min-height: 70rem;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  user-select: none;

  .selector {
    border-left: 2px solid white;
    align-self: center;
    align-content: center;
    padding-left: 2rem;
    margin-left: 30%;
    cursor: pointer;
    .selector_heading {
      font-size: 17px;
    }
    .selector_description {
      font-size: 15px;
      color: white;
    }
  }
  .applypicture {
    height: 30%;
    width: 20%;
    margin-left: 45%;
    padding: 1rem;
  }
}
@media only screen and (max-width: 868px) {
  .selectorContainer {
    background: black;
    align-content: center;
    height: 100%;
    min-height: 70rem;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    user-select: none;

    .selector {
      border-left: 2px solid white;
      align-self: center;
      justify-content: center;
      align-content: center;
      padding-left: 2rem;
      margin: 3%;

      cursor: pointer;
      .selector_heading {
        font-size: 17px;
      }
      .selector_description {
        font-size: 15px;
        color: white;
      }
    }
    .applypicture {
      height: 10rem;
      width: 10rem;
      margin-left: 25%;
      padding: 1rem;
    }
  }
}
