@import "src/Theme/colors";

.socials {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #0f0e13;
  width: 100%;
  padding: 2rem 20rem;
}

.download {
  padding-left: 5rem;
  text-align: left;
  font: normal normal normal 16px/19px Cabinet Grotesk;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #0f0e13;

  .copyRight_text {
    font: normal normal normal 16px/19px Cabinet Grotesk;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    margin-top: -15px;
  }
}

.footerCotnainer {
  overflow: hidden;
  background: $primary;

  .footerTop {
    background: $primary;
    padding: 1rem;
    // margin-top: 3rem;

    .logo {
      width: 50px;
      height: 50px;
    }

    .logoText {
      color: $darkBlue;
      font-size: 2.5rem;
      font-family: "Helvetica";
      font-weight: bold;
      padding-left: 0.5rem;
      vertical-align: middle;
    }

    .infoContainer {
      display: flex;
      text-align: left;
      flex-direction: row;
      align-items: center;

      .listTitle {
        color: $darkBlue;
        font-size: 1.4rem;
        font-family: "Helvetica";
        font-weight: 500;
        margin: 0;
        margin-bottom: 0.3rem;
      }

      .listInfo {
        color: $darkGrey;
        font-size: 1rem;
        font-family: "Helvetica";
        margin: 0;
        line-height: 1.3;
      }
    }

    .listContainer {
      display: flex;
      flex-direction: row;
    }

    .iconContainer {
      height: 30px;
      width: 30px;
      border: solid 1px $darkBlue;
      margin-right: 10px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: flex;

      svg {
        fill: $darkBlue;
      }
    }

    .footerSummery {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-size: 1.4rem;
      font-family: "Helvetica";
      color: $darkGrey;
    }

    .footerButtonHeading {
      font-size: 1.5rem;
      font-family: "Helvetica";
      color: $darkBlue;
    }

    .footerLink {
      font-size: 1.1rem;
      font-family: "Helvetica";
      color: $whiteColorHeader;
      margin-bottom: 0.5rem;
      cursor: pointer;

      a {
        cursor: pointer;
        color: $whiteColorHeader;
      }
    }

    .linkFirst {
      margin-top: 2rem;
    }

    .referinputfield {
      border-radius: 2rem;
      width: 85%;
      background: $whiteColorHeader;
      padding: 0.5rem 0.3rem;
      margin: 0;
      border: solid 1px $darkGrey;
      outline: none !important;
      color: $darkGrey;
    }

    .submitButton {
      font-family: "Helvetica";
      font-weight: bold;
      color: $whiteColorHeader;
      border: none;
      margin-top: 1rem;
      margin-left: 10px;
      width: 110px;
      padding: 8px 0;
      font-size: 0.9rem;
      background-image: linear-gradient(to left,
          $dashbaordGradient1,
          $dashbaordGradient );
    }

    .referinputfield::placeholder {
      color: $grey5;
      opacity: 1;
    }
  }

  .emailText {
    color: $darkGrey;
    font-size: 1.1rem;
    font-family: "Helvetica";
    cursor: pointer;
  }
}

.footerContainer {
  background-color: #0f0e13;
  // background-image: hsla(253, 16%, 7%, 1) 0, transparent 50%;
  padding: 1rem;

  .footerContainer_row {
    align-self: center;
    margin-left: 20%;
  }

  .f1 {
    margin: 0;
    padding: 0px 0;
    font-size: 0.8rem;
    font-family: "Helvetica";
    color: $whiteColorHeader;
  }

  .f2 {
    margin: 0;
    padding: 0px 0;
    color: $whiteColorHeader;
    font: medium medium medium 16px/19px Cabinet Grotesk;
  }
}

@media only screen and (max-width: 768px) {
  .socials {
    align-items: center;
    justify-content: space-around;
    background: #0f0e13;
    width: 100%;
    padding: 2rem 0rem;
  }

  .download {
    padding-left: 0rem;
    text-align: center;
    font: normal normal normal 16px/19px Cabinet Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background: #0f0e13;

    .copyRight_text {
      font: normal normal normal 16px/19px Cabinet Grotesk;
      letter-spacing: 1px;
      align-self: center;
      text-align: center;
      color: #ffffff;
      font-weight: 400;
      margin-top: 15px;
    }
  }

  .footerCotnainer {
    overflow: hidden;
    background: $primary;

    .footerTop {
      background: $primary;
      padding: 1rem;
      // margin-top: 3rem;

      .logo {
        width: 50px;
        height: 50px;
      }

      .logoText {
        color: $darkBlue;
        font-size: 2.5rem;
        font-family: "Helvetica";
        font-weight: bold;
        padding-left: 0.5rem;
        vertical-align: middle;
      }

      .infoContainer {
        display: flex;
        text-align: left;
        flex-direction: row;
        align-items: center;

        .listTitle {
          color: $darkBlue;
          font-size: 1.4rem;
          font-family: "Helvetica";
          font-weight: 500;
          margin: 0;
          margin-bottom: 0.3rem;
        }

        .listInfo {
          color: $darkGrey;
          font-size: 1rem;
          font-family: "Helvetica";
          margin: 0;
          line-height: 1.3;
        }
      }

      .listContainer {
        display: flex;
        flex-direction: row;
      }

      .iconContainer {
        height: 30px;
        width: 30px;
        border: solid 1px $darkBlue;
        margin-right: 10px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;

        svg {
          fill: $darkBlue;
        }
      }

      .footerSummery {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-family: "Helvetica";
        color: $darkGrey;
      }

      .footerButtonHeading {
        font-size: 1.5rem;
        font-family: "Helvetica";
        color: $darkBlue;
      }

      .footerLink {
        font-size: 1.1rem;
        font-family: "Helvetica";
        color: $whiteColorHeader;
        margin-bottom: 0.5rem;
        cursor: pointer;

        a {
          cursor: pointer;
          color: $whiteColorHeader;
        }
      }

      .linkFirst {
        margin-top: 2rem;
      }

      .referinputfield {
        border-radius: 2rem;
        width: 85%;
        background: $whiteColorHeader;
        padding: 0.5rem 0.3rem;
        margin: 0;
        border: solid 1px $darkGrey;
        outline: none !important;
        color: $darkGrey;
      }

      .submitButton {
        font-family: "Helvetica";
        font-weight: bold;
        color: $whiteColorHeader;
        border: none;
        margin-top: 1rem;
        margin-left: 10px;
        width: 110px;
        padding: 8px 0;
        font-size: 0.9rem;
        background-image: linear-gradient(to left,
            $dashbaordGradient1,
            $dashbaordGradient );
      }

      .referinputfield::placeholder {
        color: $grey5;
        opacity: 1;
      }
    }

    .emailText {
      color: $darkGrey;
      font-size: 1.1rem;
      font-family: "Helvetica";
      cursor: pointer;
    }
  }

  .footerContainer {
    background-color: #0f0e13;
    // background-image: hsla(253, 16%, 7%, 1) 0, transparent 50%;
    padding: 1rem;

    .footerContainer_row {
      align-self: flex-start;
      margin-left: 0%;
    }

    .f1 {
      margin: 0;
      padding: 0px 0;
      font-size: 0.8rem;
      font-family: "Helvetica";
      color: $whiteColorHeader;
    }

    .f2 {
      margin: 0;
      padding: 0px 0;
      color: $whiteColorHeader;
      font: medium medium medium 16px/19px Cabinet Grotesk;
    }
  }
}