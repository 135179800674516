@import "../../Theme/colors";

.feedBackContainer {
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding: 1.25rem;
  padding-left: 0;

  width: 45%;
  display: flex;

  background: rgba(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);

  .feedBackProfile {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 35%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // border-radius: 10px;
    // border: solid 1px #ebebeb;
    padding: 1rem;

    //  className=" w-full rounded-sm p-2 outline-none text-white border-none text-sm white-glassmorphism"
  }
  .profileImg {
    border-radius: 50%;
    // height: 90%;
    // width: 90%;
  }

  .profileInfo {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 70%;
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1rem;
    color: white;
  }
  .feedBackInfo {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .feedBackInfoHeading {
    tab-size: 4rem;
    table-layout: auto;
    display: flex;
    color: white;
    empty-cells: show;
  }
  .table {
    color: white;
    tab-size: 12;
    td,
    th,
    thead,
    tbody {
      border: none;
      padding: 1.5rem;
    }
  }
  .feedBackDescription {
    color: white;
    text-align: center;
    padding: 0 0 1rem 0;
    // height: 4rem;
    // display: table-cell;
  }
  .line {
    width: 100%;
    background-color: rgb(189, 189, 189);
    height: 1px;
    margin-bottom: 2rem;
  }
  .logo {
    height: 15px;
  }

  .readMoreText {
    margin: 0.5rem;
    padding: 0.25rem;
    margin-bottom: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    text-align: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .feedBackContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1.25rem;
    .feedBackProfile {
      justify-content: center;
      width: 100%;
    }
    .feedBackInfo {
      width: 100%;
    }
    .table {
      td,
      th,
      thead,
      tbody {
        padding: 1rem;
      }
    }
  }
}
