@import "src/Theme/colors";

.navigationBar {
  background: linear-gradient(
    to right top,
    #b8babe,
    #a0a7b5,
    #8893ac,
    #7081a3,
    #596e9a,
    #50699d,
    #4663a0,
    #3b5ea3,
    #4064b4,
    #486ac5,
    #516fd5,
    #5c74e6
  );

  min-height: 60px;
  width: 100%;
  padding: 10px;
  overflow-x: hidden;
  //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  .logo {
    height: 2rem;
    margin-left: 00.5rem;
    width: 6rem1;
  }
  .logoText {
    color: white;
    font-size: 1rem;
    font-family: "Helvetica";
    font-weight: 500;
    padding-left: 0.5rem;
    padding-top: 15rem;
    vertical-align: middle;
  }

  .activationCodeBt {
    font-family: "Helvetica"; 
    font-weight: bold;
    color: $whiteColorHeader;
    border: none;
    font-size: 0.9rem;
    background-image: linear-gradient(
      to left,
      $dashbaordGradient1,
      $dashbaordGradient
    );
  }
  .navlinkWeb {
    margin-left: 1rem; 
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 1rem; 
    color: whitesmoke !important;
  }
  .navlinkWebActive {
    position: relative;
    &:before {
      background-image: linear-gradient(
        to left,
        $dashbaordGradient1,
        $dashbaordGradient
      ) !important;
      top: -17px;
      left: 0;
      position: absolute;
      content: " ";
      width: 100%;
      display: block;
      height: 8px;
    }
  }
}
.navbar {
  padding: 10px 10px !important;
}
.navbar-collapse {
  width: 120%;
  //background: $backgroungColor2;
  text-align: left;
  padding-right: 0.5rem;
}

.navbar-toggle {
  width: 120%;
  background: $backgroungColor2;
  text-align: right;
  padding-right: 0.5rem;
}
nav.navbar {
  // background-image: linear-gradient(
  //   to right,
  //   #181a1d,
  //   #1e2024,
  //   #24262b,
  //   #2a2d32,
  //   #30333a,
  //   #33363d,
  //   #363941,
  //   #393c44,
  //   #3a3c43,
  //   #3b3d43,
  //   #3c3d42,
  //   #3d3e42
  // );
  // background-image: linear-gradient(to bottom right, #506775 0%, #7e8c97 99%);
  background-color: #0f0e13;
  background-image: radial-gradient(
      at 0% 100%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 100%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 100%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}
.bookSession {
  display: flex;
  border: 1px solid #fff;
  background-color: transparent;
  color: white !important;
  border-radius: 20px;
  padding: 0.5em 10px;
  font-weight: bold;
  margin-left: auto;
  margin-top: 10px;
  font-size: 13px;
  cursor: pointer;
}
.bookSession:hover {
  color: white;
}

@media only screen and (max-width: 768px) {
  .bookSession {
    margin-top: 10px !important;
    margin-right: 50px !important;
  }
}
