@import "src/Theme/colors";

.parentPage {
  overflow: hidden;

  .bannerContainer2 {
    .feedbackHeading2 {
      color: $whiteColorHeader;
      font-size: 4vmax; 
      font-family: "Helvetica";
      padding-top: 5vh;  
      font-weight: bold;
      line-height: 1em;
      text-align: center;
    }

    .feedbackHeading {
      color: $whiteColorHeader;
      font-size: 3.1em; 
      font-family: "Helvetica";
      font-weight: bold; 
      padding-top: 10vh;
      padding-bottom: 10vh;
      text-align: center;
    }

    .feedBackRow {
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 2rem;
      padding-bottom: 0;
    }

    .viewall {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .viewButtonSection {
        width: 20%;
        padding: 1rem;
      }

      .viewAllButton {
        border: white;
        color: white;
        text-decoration: none;
        width: 100%;
        padding: 0.5rem 0;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: white;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .videoContainer {
    padding: 0;
    margin-bottom: 0;
  }

  .bannerContainer2 {
    background-color: #0f0e13;
    background-image: radial-gradient(at 0% 100%,
        hsla(253, 16%, 7%, 1) 0,
        transparent 50%),
      radial-gradient(at 50% 0%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
  }

  .bannerContainer {
    padding: 2rem;
    background-color: #0f0e13;
    background-image: radial-gradient(at 0% 0%,
        hsla(253, 16%, 7%, 1) 0,
        transparent 50%),
      radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
      radial-gradient(at 100% 0%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
    overflow: hidden;

    .bannerHeading {
      color: $primary;
      font-size: 2.2vmax !important;
      font-family: "Helvetica";
      font-weight: 500;
      text-align: center;
      padding-top: 2vmax;
      padding-bottom: 2vmax;
      padding-left: 20px;
      padding-right: 20px;
    }

    .bannerImage {
      width: 36rem;
    }

    .bannerSubHeading {
      color: $headerMenuColor;
      font-size: 1.3rem;
      font-family: "Helvetica";
    }

    .sideheading {
      font-family: "Helvetica";
      font-weight: bold;
      font-size: 1.5rem;
      //font-weight: bolder;
      color: $whiteColorHeader;
    }

    .quickLink {
      color: $whiteColorHeader;
      text-decoration: underline;
    }
  }

  .alightRight {
    text-align: right;
  }

  .enrollMessageTitle {
    color: $darkBlue;
    font-size: 2.5rem;
    margin: 0;
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    padding: 5rem 0 0.2rem 0;
  }

  .enrollMessageTitle_doctor {
    color: $darkBlue;
    font-size: 2.5rem;
    margin: 0;
    font-weight: bold;
    font-family: "Helvetica";
    text-align: center;
    padding: 5rem 0 0.2rem 0;
  }

  .enrollMessage {
    margin: 0;
    color: $headerMenuColor;
    font-size: 1.5rem;
   font-family: "Helvetica";
    text-align: center;
  }

  .enrollMessage1 {
    margin: 0 0 1rem 0;
    color: $headerMenuColor;
    font-size: 1rem;
   font-family: "Helvetica";
    text-align: center;
  }

  .listContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
  }

  .iconContainer {
    height: 35px;
    width: 35px;
    border: solid 1px $darkBlue;
    margin-right: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;

    svg {
      fill: $darkBlue;
    }
  }

  .infoContainer {
    flex: 1;
    text-align: left;

    .listTitle {
      color: $darkBlue;
      font-size: 1.4rem;
      font-family: "Helvetica";
      font-weight: 500;
      margin: 0;
      margin-bottom: 0.3rem;
    }

    .listInfo {
      color: $darkGrey;
      font-size: 0.9rem;
     font-family: "Helvetica";
      margin: 0;
      line-height: 1.3;
    }
  }

  .submitButton {
    margin-top: 10px;
    color: white;
  }

  .submitButton:hover {
    color: white;
  }

  .patientImage {
    border-radius: 1rem;
  }

  .referContainer_doctor {
    background: $lightGrey !important;
    padding: 4rem 2rem;
    margin: 2rem 0;
  }

  .blocksContainer {
    background: $whiteColorHeader;
    padding: 3rem 2rem 3rem 2rem;
  }

  .innerContainer {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }

  .innerContainer1 {
    width: 40%;
    margin: 0 auto;
    text-align: center;
  }

  .box {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06),
      0px 2px 14px rgba(0, 0, 0, 0.04), 0px 6px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 0.5rem;
    margin: 10px;

    .boxIcon {
      padding: 2rem 0 1rem 0;
      width: 6rem;
    }

    .boxTitle {
     font-family: "Helvetica";
      font-weight: bold;
      font-size: 1rem;
      color: $darkBlue;
      padding-bottom: 0.5rem;
    }
  }

  .forDoctorImage {
    text-align: right;
    width: 30rem;
    margin-top: 2rem;
  }

  .doctorContaienr {
    padding-bottom: 7rem;
    padding-top: 4rem;
  }

  .forDoctorColumn {
    background: $peach;
  }

  .lastFooterLine {
    margin: 0 auto;
    text-align: center;
  }

  .getInTouchBt {
    border: none;
    margin: 1rem 0 2rem 0;
    border-radius: 0.5rem;
    padding: 0.5rem 2rem;
    background-image: linear-gradient(to left,
        $dashbaordGradient1,
        $dashbaordGradient ) !important;
    font-size: 1.2rem;
   font-family: "Helvetica";
    text-align: center;
  }

  .docPatientSection {
    padding: 0 2rem;
  }
}

.homeText {
  overflow: hidden;
  font-size: 18px;
  padding-left: 5vw;
  padding-top: 11vmin;
  padding-bottom: 16vmin;

  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 0%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%),
    radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(339, 49%, 30%, 1) 0, transparent 50%);

  .textSection {
    margin-top: 15px;
  }

  .upperTextFont {
    font-family: "Helvetica"; 
    font-weight: bold;
    z-index: 1;
    color: white;
    font-size: 5.7vmax;
    line-height: 6vmax;
  }

  .bannerImage {
    margin: 0;
    padding: 0;
  }

  .lowerText {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 0px;
    font-size: 4vmin;
    color: $whiteColorHeader;
    font-family: "Helvetica";
  }
}

.personalizations {
  overflow: hidden;
  // background: rgb(3, 0, 3);
  padding-top: 20vh;
  padding-bottom: 10vh;

  .heading3 {
    margin-bottom: 0em;
    color: white;
    font-size: 3.1em; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    letter-spacing: 0.5vw;
  }
}

.healthGoals {
  margin-top: -10px;
  overflow: hidden;
  // background-color: #0f0e13;
  // background-image: radial-gradient(
  //     at 0% 100%,
  //     hsla(253, 16%, 7%, 1) 0,
  //     transparent 50%
  //   ),
  //   radial-gradient(at 50% 0%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 0%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%),
    radial-gradient(at 50% 100%, hsla(225, 39%, 25%, 1) 0, transparent 50%);

  padding-top: 15vmin;

  .heading {
    margin-bottom: 5vmin;
    color: $whiteColorHeader;
    font-size: 4vmax; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    letter-spacing: 0.2vw;
  }

  .heading4 {
    margin-top: 10vmin;
    padding-left: 10em;
    padding-right: 10em;
    padding-bottom: 3em;
    color: $whiteColorHeader;
    font-size: 1.5vmax;
    font-family: "Helvetica";
    text-align: center; 
  }

  .text1 { 
    color: $whiteColorHeader;
    font-size: 2vmax; 
    font-family: "Helvetica";
    text-align: center; 
  }

  .text2 { 
    margin-bottom: 10vmin;
    color: $whiteColorHeader;
    font-size: 1.5vmax; 
    font-family: "Helvetica";
    text-align: center; 
  }

  .goalLogoContainer {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 40%;
    align-items: center;
    margin: 0 auto;

    .goalLogoCol {
      text-align: center;
      background-color: white;
      border-radius: 5%;
      margin: 5px;
      min-width: 160px;
      min-height: 160px;
      padding: 2px;
      //   transition: "transform 3s";
    }

    .goalLogoImage {
      background: white;
      display: inline-block;
      padding: 0.2em;
      margin-bottom: 10px;
      margin-top: 10px;
      color: orange;
    }
  }

  .heading2 {
    color: $whiteColorHeader;
    font-size: 4vw; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    //letter-spacing: 0.5vw;
  }

  .heading3 {
    margin-bottom: 0em;
    color: $whiteColorHeader;
    font-size: 3.1em; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    letter-spacing: 0.5vw;
  }

  .sidebars {
    text-align: center;
    color: $whiteColorHeader;
    font-size: 18px;

    // background: $whiteBackground;
    .images {
      background: white;
      height: 3em;
      width: 3em;
      padding: 5%;
      margin-bottom: 0.5em;
    }
  }
}

.healthGoals_blue_bk {
  overflow: hidden;
  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 0%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%),
    radial-gradient(at 50% 100%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
  padding-top: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .heading2 {
    color: $whiteColorHeader;
    font-size: 4vmax; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    //letter-spacing: 0.5vw;
  }

  .transformation {
    display: flex;
    flex-direction: column;
    width: 100%;
    // width: 70%;
    // margin: 0 auto;
    padding: 4rem;
    justify-content: space-between;
    align-items: center;
    // background: rgba(39, 51, 89, 0.4);
    // border-radius: 16px;
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);
    // border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .transformationrow {
    margin: 0 auto;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3rem 7rem;
    justify-content: space-between;
  }

  .transformationcell {
    padding: 30px auto;
    margin-bottom: 60px;
    background-color: white;
    text-align: center;
    height: 180px;
    width: 180px;
    display: flex;
    flex-direction: column;
    // justify-items: space-betwen;
    justify-content: space-around;
    align-items: center;
    border-radius: 5%;
    margin: 5px;
    background: rgba(39, 51, 89, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);

    padding: 2px;
  }

  .transformationname {
    // width: 33%;
    padding-top: 1vmin;
    text-align: center;
    font-size: 2.8vmin; 
    color: $whiteBackground;
    font-family: "Helvetica";
    font-weight: bold; 
    font-weight: 700;
  }

  .transformationvalue {
    text-align: center;
    font-size: 3vmin;

    color: #9feb79;
    text-shadow: 2px 2px #355326;
    // padding-top: 1.25rem;
  }
}

.healthGoals_wh_bk {
  overflow: hidden;
  background: $whiteBackground;
  padding-top: 20vh;
  padding-bottom: 10vh;

  .heading2 {
    color: #001f3f;
    font-size: 4vw; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 1em;
    //letter-spacing: 0.5vw;
  }
}

.videocomp {
  position: absolute;
}

.theAppSection_blue_bk {
  overflow: hidden;

  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 100%,
      hsla(253, 16%, 7%, 1) 0,
      transparent 50%),
    radial-gradient(at 50% 60%, hsla(225, 39%, 25%, 1) 0, transparent 50%),
    radial-gradient(at 50% 0%, hsla(225, 39%, 25%, 1) 0, transparent 50%);

  padding-bottom: 2em;

  .heading {
    margin-top: 10vh;
    margin-bottom: 10vh;
    color: $whiteColorHeader;
    font-size: 4vmax; 
    font-stretch: 100%;
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center;
    line-height: 10vh; 
  }

  .SubHeading {
    margin-top: 5vh;
    color: $whiteColorHeader;
    font-size: 2em; 
    font-family: "Helvetica";
    font-weight: bold;
    text-align: center; 
  }

  .sideTextRow {
    margin-bottom: 5vh;
  }

  .sidetext {
    margin-top: 15vh;
    margin-bottom: 15vh;
    padding: 5vmin;
    color: $whiteColorHeader;
    font-size: 7vmin; 
    font-family: "Helvetica";
    line-height: 1em;
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .homeText {
    padding-bottom: 10rem;

    .bannerImage {
      margin: 0;
      padding-left: 20px;
      padding-right: 30px;
      padding-bottom: 5rem;
    }
  }

  .healthGoals .heading4 {
    margin-top: 8vmin;
    padding-left: 5em;
    padding-right: 5em;
    font-family: "Helvetica";
    text-align: center;
  }

  .healthGoals .goalLogoContainer {
    width: 100%;
    margin: 0;
  }

  .theAppSection_blue_bk {
    margin-top: -2px;

    .heading {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 5vmin;
      color: white;
      font-size: 4vmax; 
      font-family: "Helvetica";
      font-weight: bold;
      text-align: center;
      line-height: 1em;
      letter-spacing: 0.2vw;
    }
  }

  .healthGoals_blue_bk {
    margin-top: -2px;
    padding-top: 0;

    .heading2 {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 5vmin;
      color: white;
      font-size: 4vmax; 
      font-family: "Helvetica";
      font-weight: bold;
      text-align: center;
      line-height: 1em;
      letter-spacing: 0.2vw;
    }

    .transformation {
      display: flex;
      width: 100%;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
    }

    .transformationrow {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    .transformationcell {
      margin-bottom: 40px;

      text-align: center;
      height: 140px;
      width: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5%;
    }

    .transformationname {
      padding-top: 1vmin;
      text-align: center;
      font-size: 4vmin;
    }

    .transformationvalue {
      font-size: 4vmin;
    }
  }

  .personalizations .heading3 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 2.7em;
  }

  .parentPage .bannerContainer {
    .bannerHeading {
      font-size: 1.5rem !important;
      font-family: "Helvetica";
      font-weight: 500;
      text-align: center;
      padding-top: 5vmax;
      padding-bottom: 5vmax;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .goalImage {
    overflow: hidden;
    margin-left: 50px;
  }

  .bannerContainer2 {
    margin-top: -2px;

    .feedbackHeading2 {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      color: white;
      font-size: 4vmax; 
      font-family: "Helvetica";
      font-weight: bold;
      text-align: center;
      line-height: 1em;
      letter-spacing: 0.2vw;
    }

    .viewallbutton {
      width: 30%;
    }
  }
}

.texts {
  color: rgb(33, 37, 41);
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 0.1rem;
}

.feedBackTile {
  padding-top: 8rem;
  padding-bottom: 3rem;

  .readMoreText {
    color: $primary;
    text-align: center;
    cursor: pointer;
  }

  .headline1Container {
    text-align: center;

    .headline1Style {
      font-family: "Helvetica";
      font-size: 1.3rem;
      margin-top: 2rem;
    }
  }

  .headline2Container {
    text-align: center;
    font-family: "Helvetica";

    .headline2Style {
      line-height: 1rem;
    }
  }

  .noteText {
    margin-left: 2em;
    font-size: 0.8rem;
    margin-top: 2rem;
    margin-right: 4rem;
    //height:100px;
    overflow: hidden;
  }
}

.strangeText {
  overflow: hidden;
  background-color: $whiteColorHeader;
  color: #011f40;
  text-align: center;
  justify-content: center;
  line-height: 1.2em;
  font-size: 2.2vmax !important;
  font-family: "Helvetica";
  font-weight: 500;
  margin-bottom: 0;
  padding: 10vh 0;
}

.overlayText {
  font-size: 2vmax;
  line-height: 2vmax;
  font-family: "Helvetica";
  font-weight: bold;
  letter-spacing: 0.1vmin;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .healthGoals .goalLogoContainer {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .healthGoals .goalLogoContainer {
    width: 60%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .theAppSection_blue_bk {
    .sidetext {
      text-align: center;
      margin-top: 1vh;
      margin-bottom: 1vh;
      padding: 0 auto;
      color: $whiteColorHeader;
      font-size: 5vmin;
      font-family: "Helvetica";
      line-height: 1em;
    }

    .sideTextRow {
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
  }
}

.submitButton {
  margin-top: 10px;
}

.appstoreIcon {
  width: 260px;
  height: 70px;
  border-radius: 10px;
}